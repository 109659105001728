const VrUrl = () => {

    let link = '';
    if ( window.location.hostname === 'localhost') {
        // link = 'http://localhost:4200'
        link = 'https://vr-piotrowice.hekko24.pl/'
    }else{
        link = 'https://vr-piotrowice.hekko24.pl/'
    }

    return link;
}

export default VrUrl;